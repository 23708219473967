<template>
  <b-modal size="xl" v-model="active" header-bg-variant="primary">
    <div slot="modal-header">
        <h2 class="mb-0">Invoice {{ invoice.id }}</h2>
        <small>{{ invoice.description }}</small>
    </div>
    <h4>Charges</h4>
    <b-table :hover="true" :striped="true" :bordered="false" :small="true" :fixed="false" :items="charges" :fields="chargeFields" foot-clone>
      <template v-slot:cell(price)="data">
        <dollars :value="data.value"></dollars>
      </template>
      <template v-slot:cell(total)="data">
        <dollars :value="data.value"></dollars>
      </template>
      <template v-slot:foot(description)><span></span></template>
      <template v-slot:foot(quantity)><span></span></template>
      <template v-slot:foot(price)><span></span></template>
      <template v-slot:foot(total)>
        <dollars :value="invoice.totalCharges"></dollars>
      </template>
    </b-table>
    <h4>Payments</h4>
    <p v-if="transactions.length == 0">No Payments</p>
    <b-table v-if="transactions.length > 0" :hover="true" :striped="true" :bordered="false" :small="true" :fixed="false" :items="transactions" :fields="transactionFields" foot-clone>
      <template v-slot:cell(createdAt)="data">
        <date :value="data.value" format="MM/DD/YYYY"></date>
      </template>
      <template v-slot:cell(amount)="data">
        <dollars :value="data.value"></dollars>
      </template>
      <template v-slot:foot(createdAt)><span></span></template>
      <template v-slot:foot(transactionId)><span></span></template>
      <template v-slot:foot(amount)>
        <dollars :value="invoice.totalPayments"></dollars>
      </template>
    </b-table>
    <p class="text-right balance-due">
      <strong>Balance Due:</strong> <dollars :value="invoice.balance"></dollars>
    </p>
    <div slot="modal-footer">
      <b-button v-if="$can('pay', 'invoices') && invoice.balance > 0" size="sm" variant="success" @click="pay(invoice)">Pay</b-button>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'

export default {
  name: 'InvoiceModal',
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  components: {
    Date,
    Dollars
  },
  data () {
    return {
      active: false,
      invoice: {
        id: null,
        organizationId: null,
        description: null,
        totalCharges: 0.0,
        totalPayments: 0.0,
        balance: 0.0,
        charges: [],
        transactions: []
      },
      charges: [],
      chargeFields: [
        { key: 'description', label: 'Description' },
        { key: 'quantity', class: 'text-right' },
        { key: 'price', label: 'Price', class: 'text-right' },
        { key: 'total', class: 'text-right' }
      ],
      transactions: [],
      transactionFields: [
        { key: 'createdAt', label: 'Date'},
        { key: 'transactionId', label: 'Txn Id' },
        { key: 'amount', label: 'Amount', class: 'text-right' }
      ]
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    pay (invoice) {
      this.$emit('pay', invoice)
      this.close()
    },
    close () {
      this.active = false
    },
    show (invoice) {
      this.active = true
      if (invoice) {
        this.invoice = { ...invoice }
        this.charges = []
        this.transactions = []
        this.fetchCharges()
        this.fetchTransactions()
      }
    },
    fetchCharges () {
      this.$api.payments.charges.getWithInvoice({ invoiceId: this.invoice.id }).then((response) => {
        this.charges = response.data
      }).catch((err) => {
        console.log('Error Fetching Charges')
      })
    },
    fetchTransactions () {
      this.$api.payments.transactions.getWithInvoice({ invoiceId: this.invoice.id }).then((response) => {
        this.transactions = response.data.filter(o => o.approved)
      }).catch((err) => {
        console.log('Error Fetching Transactions')
      })
    }
  },
  created () {
    this.$bus.$on('modal:viewInvoice', (invoice) => {
      this.show(invoice);
      // this.$nextTick(() => { this.$v.$reset() })
    });
  },
  validations: {
  }
}
</script>

<style lang="scss">
p {
  &.balance-due {
    font-size: 1.3125rem;
  }
}
</style>
