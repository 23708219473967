<template>
  <b-modal title="Modify Subscription" size="lg" v-model="active">
    <b-row class="form-group">
      <b-col sm="6">
        <label for="startDate">Effective Date</label>
        <date :value="subscription.startDate" @update="startDate"></date>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="4">
        <b-form-group label="Base Fee" label-for="baseFee">
          <b-input-group>
            <b-form-input id="baseFee" type="text" v-model="subscription.baseFee"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="User Fee" label-for="userFee">
          <b-input-group>
            <b-form-input id="userFee" type="text" v-model="subscription.userFee"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Licensed Users" label-for="numUsers">
          <b-input-group>
            <b-form-input id="numUsers" type="text" v-model="subscription.numUsers"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        Save
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'

import Date from '@/components/inputs/Date'

export default {
  name: 'SubscriptionModal',
  components: {
    Date
  },
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  data () {
    return {
      active: false,
      subscription: {
        startDate: moment().format('YYYY-MM-DD'),
        baseFee: 50.00,
        userFee: 49.00,
        numUsers: 0
      }
    }
  },
  methods: {
    startDate (value) {
      this.subscription.startDate = value
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (subscription) {
      this.active = true
      if (subscription) {
        this.subscription = subscription
      } else {
        this.subscription.startDate = moment().format('YYYY-MM-DD')
      }
    },
    save () {
      let subscription = this.subscription
      this.$emit('save', { ...subscription })
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:manageSubscription', (subscription) => {
      this.show(subscription);
      this.$nextTick(() => { this.$v.$reset() })
    });
  },
  validations: {
  }
}
</script>
