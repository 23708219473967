<template>
  <b-card no-header>
    <template slot="header">
      Subscription
    </template>
    <b-list-group>
      <p class="mb-0" v-if="subscriptions.length === 0">No Active Subscription</p>
      <b-list-group-item v-for="subscription in subscriptions" :variant="!subscription.isActive ? 'light': null" :key="subscription.id" class="d-flex justify-content-between align-items-center">
        <span>
          <strong>Licensed Users: </strong> {{ subscription.numUsers }}<br />
          <strong>Monthly Rate: </strong> <dollars :value="subscription.monthlyRate"></dollars><br />
          <small>Base Fee: <dollars :value="subscription.baseFee"></dollars>, User Fee: <dollars :value="subscription.userFee"></dollars>/user</small>
        </span>
        <b-badge v-if="subscription.isActive" variant="success" pill>Active</b-badge>
        <b-badge v-if="!subscription.isActive" variant="warning" pill>Starting: <date :value="subscription.startDate" format="MM/DD/YYYY"></date></b-badge>
      </b-list-group-item>
    </b-list-group>
    <template slot="footer" v-if="$can('manage', 'subscriptions')">
      <b-row>
        <b-col sm="12" class="text-right">
          <b-button size="sm" variant="primary" class="mr-2" @click="generateInvoice">Generate Invoice</b-button>
          <b-button size="sm" variant="primary" @click="manageSubscription">Modify Subscription</b-button>
        </b-col>
      </b-row>
    </template>
    <subscription-modal @save="saveSubscription"></subscription-modal>
    <subscription-invoice-modal @save="invoiceGenerated"></subscription-invoice-modal>
  </b-card>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'

import SubscriptionModal from '../modals/Subscription'
import SubscriptionInvoiceModal from '../modals/SubscriptionInvoice'

import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'

export default {
  name: 'Subscription',
  props: [ 'organizationId' ],
  components: {
    Date,
    Dollars,
    SubscriptionModal,
    SubscriptionInvoiceModal
  },
  data () {
    return {
      subscriptions: []
    }
  },
  methods: {
    fetchSubscriptions () {
      if (!this.organizationId) {
        return
      }
      this.$api.payments.subscriptions.get({ organizationId: this.organizationId }).then((response) => {
        this.subscriptions = response.data
      })
    },
    saveSubscription (subscription) {
      this.$api.payments.subscriptions.upsert({ organizationId: this.organizationId }, subscription).then(() => {
        this.$snotify.success('Subscription Saved')
        this.fetchSubscriptions()
      }).catch((err) => {
        this.$snotify.error('Subscription Save Failed')
      })
    },
    manageSubscription () {
      this.$bus.$emit('modal:manageSubscription')
    },
    generateInvoice () {
      this.$bus.$emit('modal:generateInvoice', this.organizationId)
    },
    invoiceGenerated () {
      this.$bus.$emit('refreshInvoices')
    }
  },
  created () {
    this.subscriptions = []
    this.fetchSubscriptions()
  },
  watch: {
    organizationId: {
      handler: function () {
        this.fetchSubscriptions()
      }
    }
  }
}
</script>
