<template>
  <b-card no-header>
    <template slot="header">
      Invoice History
      <b-button v-if="$can('manage', 'invoices')" size="sm" variant="primary" class="pull-right" @click="createInvoice()">Create Invoice</b-button>
    </template>
    <p class="mb-0" v-if="invoices.length === 0">No Invoices</p>
    <b-table v-if="invoices.length > 0" :hover="true" :striped="true" :bordered="false" :small="false" :fixed="false" :items="invoices" :fields="invoiceFields" @row-clicked="showInvoice">
      <template v-slot:cell(createdAt)="data">
        <date :value="data.value" format="MM/DD/YYYY"></date>
      </template>
      <template v-slot:cell(totalCharges)="data">
        <dollars :value="data.value"></dollars>
      </template>
      <template v-slot:cell(totalPayments)="data">
        <dollars :value="data.value"></dollars>
      </template>
      <template v-slot:cell(balance)="data">
        <dollars :value="data.value"></dollars>
      </template>
      <template v-slot:cell(actions)="data">
        <b-btn size="sm" variant="outline-success" v-if="$can('pay', 'invoices') && data.item.balance > 0" class="pay mr-2" @click.stop="pay(data.item)"><strong><i class="fa fa-dollar"></i> Pay</strong></b-btn>
        <b-btn size="sm" variant="outline-primary" :disabled="pdfLoading" @click.stop="pdf(data.item)" class="mr-2"><i class="fa fa-file-pdf-o"></i> PDF</b-btn>
        <b-btn size="sm" variant="outline-primary" :disabled="pdfSending" @click.stop="send(data.item)"><i class="fa fa-envelope-o"></i> Email</b-btn>
      </template>
    </b-table>
    <create-invoice-modal :organizationId="organizationId" @save="fetchInvoices"></create-invoice-modal>
    <invoice-modal @pay="pay"></invoice-modal>
    <pay-modal @payment="fetchInvoices"></pay-modal>
  </b-card>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/axios'

import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'
import CreateInvoiceModal from '../modals/CreateInvoice'
import InvoiceModal from '../modals/Invoice'
import PayModal from '../modals/Pay'

export default {
  name: 'Invoices',
  props: [ 'organizationId' ],
  components: {
    CreateInvoiceModal,
    Date,
    Dollars,
    InvoiceModal,
    PayModal
  },
  data () {
    return {
      pdfLoading: false,
      pdfSending: false,
      invoices: [],
      invoiceFields: [
        { key: 'id', label: '#' },
        { key: 'createdAt', label: 'Date' },
        { key: 'description', label: 'Description', class: 'no-wrap' },
        { key: 'totalCharges', label: 'Charges', class: 'numeric text-right' },
        { key: 'totalPayments', label: 'Payments', class: 'numeric text-right' },
        { key: 'balance', label: 'Balance Due', class: 'numeric text-right' },
        { key: 'actions', label: '', class: 'text-right' }
      ],
    }
  },
  methods: {
    send (invoice) {
      this.pdfSending = true
      axios({
        method: 'post',
        url: `/v1/payments/invoices/send/${invoice.id}`
      })
      .then(response => {
        this.pdfSending = false
        this.$snotify.success('Invoice Emailed to Billing Contact')
      })
      .catch(() => {
        this.$snotify.error('Error Sending Invoice')
      })
    },
    pdf (invoice) {
      this.pdfLoading = true
      axios({
        method: 'get',
        url: `/v1/payments/invoices/${invoice.id}/pdf`,
        responseType: 'arraybuffer'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Invoice ${invoice.id}.pdf`) //or any other extension
        document.body.appendChild(link)
        link.click()
        this.pdfLoading = false
      })
      .catch(() => {
        this.$snotify.error('Error Loading PDF')
      })
    },
    pay (invoice) {
      this.$bus.$emit('modal:payInvoice', invoice)
    },
    createInvoice () {
      this.$bus.$emit('modal:createInvoice')
    },
    showInvoice (invoice) {
      this.$bus.$emit('modal:viewInvoice', invoice)
    },
    fetchInvoices () {
      if (!this.organizationId) {
        return
      }
      this.$api.payments.invoices.getWithOrganization({ organizationId: this.organizationId }).then((response) => {
        this.invoices = response.data
      })
    },
  },
  created () {
    this.invoices = []
    this.fetchInvoices()

    this.$bus.$on('refreshInvoices', () => {
      this.fetchInvoices()
    });
  },
  watch: {
    organizationId: {
      handler: function () {
        this.fetchInvoices()
      }
    }
  }
}
</script>

<style lang="scss">
td {
  &.no-wrap {
    white-space: nowrap;
  }
  .btn {
    position: relative;
    top: -1px;
    line-height: .75rem;
  }
}
</style>
