<template>
  <b-modal :title="title" size="lg" v-model="active">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Card Name" label-for="name">
          <b-input-group>
            <b-form-input id="name" type="text" v-model="profile.name"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <b-form-group label="Card Number" label-for="cardNumber">
          <b-input-group>
            <b-form-input id="cardNumber" type="text" v-model="profile.cardNumber" :placeholder="mask"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Exp Month" label-for="expMonth">
          <b-input-group>
            <b-form-input id="expMonth" type="text" v-model="expMonth" placeholder="eg. 12"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Exp Year" label-for="expYear">
          <b-input-group>
            <b-form-input id="expYear" type="text" v-model="expYear" placeholder="eg. 2020"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label="Postal Code" label-for="zipcode">
          <b-input-group>
            <b-form-input id="zipcode" type="text" v-model="profile.zipcode"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-checkbox v-model="profile.default"> Default Card</b-form-checkbox>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        Save Card
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'CreditCardModal',
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    }),
    title: function () {
      if (this.profile.id) {
        return 'Edit Credit Card'
      } else {
        return 'Add Credit Card'
      }
    }
  },
  data () {
    return {
      active: false,
      profile: {
        id: null,
        name: null,
        expiration: null,
        cardNumber: null,
        organizationId: null,
        zipcode: null
      },
      mask: null,
      expMonth: null,
      expYear: null
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (profile) {
      this.active = true
      if (profile) {
        this.profile = { ...profile }
        this.mask = `XXXX-XXXX-XXXX-${profile.cardNumber}`
        this.profile.cardNumber = null
        const expiration = moment(profile.expiration)

        this.expMonth = expiration.format('MM')
        this.expYear = expiration.format('YYYY')
      } else {
        this.mask = null
        this.expMonth = null
        this.expYear = null
        this.profile = {
          id: null
        }
      }
    },
    save () {
      let profile = this.profile
      if (profile.cardNumber) {
        profile.cardNumber = profile.cardNumber.replace(/\D/g, '')
      }
      profile.expiration = moment(`${this.expMonth}-${this.expYear}`, 'MM-YYYY').format('YYYY-MM-DD')
      this.$emit('save', { ...profile })
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:editCreditCard', (profile) => {
      this.show(profile);
      this.$nextTick(() => { this.$v.$reset() })
    });
  },
  validations: {
  }
}
</script>
