<template>
  <b-modal title="New Invoice" size="xl" v-model="active" header-bg-variant="primary">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Description" label-for="description">
          <b-input-group>
            <b-form-input id="description" type="text" v-model="invoice.description"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <h3>Charges</h3>
    <b-table :hover="false" :striped="true" :bordered="false" :small="true" :fixed="false" :items="charges" :fields="chargeFields" foot-clone>
      <template v-slot:cell(description)="data">
        <b-form-input v-model="data.item.description" size="sm"></b-form-input>
      </template>
      <template v-slot:cell(quantity)="data">
        <b-form-input v-model="data.item.quantity" size="sm" style="text-align: right;"></b-form-input>
      </template>
      <template v-slot:cell(price)="data">
        <b-form-input v-model="data.item.price" size="sm" style="text-align: right;"></b-form-input>
      </template>
      <template v-slot:cell(total)="data">
        <dollars :value="data.item.total"></dollars>
      </template>
      <template v-slot:cell(action)="data">
        <b-button v-if="charges.length > 1" size="sm" variant="ghost-danger" @click.stop="remove(data.item)"><i class="fa fa-remove"></i></b-button>
      </template>
      <template v-slot:foot(description)><span><a href="javascript:void(0)" @click="addRow()">+ Add Row</a></span></template>
      <template v-slot:foot(quantity)><span></span></template>
      <template v-slot:foot(price)><span></span></template>
      <template v-slot:foot(total)>
        <dollars :value="invoice.totalCharges"></dollars>
      </template>
    </b-table>
    <div slot="modal-footer">
      <b-button size="sm" variant="success" @click="save()">Save</b-button>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'

export default {
  name: 'CreateInvoiceModal',
  props: [ 'organizationId' ],
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  components: {
    Date,
    Dollars
  },
  data () {
    return {
      active: false,
      invoice: {
        id: null,
        organizationId: null,
        description: null,
        totalCharges: 0.0,
        totalPayments: 0.0,
        balance: 0.0,
        charges: [],
        transactions: []
      },
      charges: [],
      chargeFields: [
        { key: 'description', label: 'Description' },
        { key: 'quantity', class: 'text-right narrow' },
        { key: 'price', label: 'Price', class: 'text-right narrow' },
        { key: 'total', class: 'text-right narrow' },
        { key: 'action', label: '', class: "text-right action" }
      ]
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    addRow () {
      this.charges.push({ description: null, quantity: 0, price: 0, total: 0 })
    },
    remove (item) {
      let index = this.charges.indexOf(item)
      this.charges.splice(index, 1)
    },
    save () {
      this.$api.payments.invoices.create(this.invoice).then((response) => {
        const invoice = response.data
        const allCharges = this.charges.map((charge) => {
          charge.invoiceId = invoice.id
          return this.$api.payments.charges.create(charge)
        })

        Promise.all(allCharges).then(() => {
          this.$emit('save')
          this.close()
        })
      }).catch((err) => {
        console.log('Error Creating Invoice')
      })
    },
    close () {
      this.active = false
    },
    show () {
      this.active = true
      this.invoice.organizationId = this.organizationId
      this.charges = [
        { description: null, quantity: 0, price: 0, total: 0 }
      ]
    }
  },
  created () {
    this.$bus.$on('modal:createInvoice', () => {
      this.show()
    });
  },
  watch: {
    charges: {
      handler: function () {
        this.invoice.totalCharges = this.charges.reduce((total, charge) => {
          charge.total = charge.quantity * charge.price
          return total += charge.total
        }, 0.0)
      },
      deep: true
    }
  },
  validations: {
  }
}
</script>

<style lang="scss">
.table-sm {
  td {
    &.narrow {
      width: 10rem;
    }

    &.action {
      width: 4rem;
    }
  }
}
</style>


