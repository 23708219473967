<template>
  <b-modal :title="`Pay Invoice ${invoice.id}`" size="md" v-model="active">
    <b-form-group label="Amount" label-for="amount">
      <b-input-group>
        <b-form-input id="amount" type="text" v-model="amount"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-alert :show="(declinedMessage != null)" variant="danger">{{ declinedMessage }}</b-alert>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="submitPayment()">
        Submit Payment
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'CreditCardModal',
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  data () {
    return {
      active: false,
      amount: null,
      declinedMessage: null,
      invoice: {
        id: null,
        organizationId: null,
        description: null,
        totalCharges: 0.0,
        totalPayments: 0.0,
        balance: 0.0,
        charges: [],
        transactions: []
      }
    }
  },
  methods: {
    close () {
      this.active = false
    },
    submitPayment () {
      this.$api.payments.invoices.pay({ id: this.invoice.id }, { amount: this.amount }).then((response) => {
        this.$emit('payment')
        this.active = false
      }).catch((err) => {
        const response = err.response
        this.declinedMessage = `DECLINED: ${response.data.message}`
      })
    },
    show (invoice) {
      this.active = true
      if (invoice) {
        this.invoice = { ...invoice }
        this.amount = invoice.balance
      }
    }
  },
  created () {
    this.$bus.$on('modal:payInvoice', (invoice) => {
      this.declinedMessage = null
      this.show(invoice);
      this.$nextTick(() => { this.$v.$reset() })
    });
  },
  validations: {
  }
}
</script>
