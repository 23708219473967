<template>
  <b-modal title="Generate Invoice for Subscription" size="lg" v-model="active">
    <b-row class="form-group">
      <b-col sm="6">
        <label for="date">Month</label>
        <date :value="date" @update="updateDate" :showDay="false"></date>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="generate()">
        Generate
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'

import Date from '@/components/inputs/Date'

export default {
  name: 'SubscriptionInvoiceModal',
  components: {
    Date
  },
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  data () {
    return {
      active: false,
      date: moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    updateDate (value) {
      this.date = value
    },
    show (organizationId) {
      this.active = true
      this.organizationId = organizationId
      this.date = moment().format('YYYY-MM-DD')
    },
    close () {
      this.active = false
    },
    generate () {
      this.$api.payments.subscriptions.generateInvoice({ organizationId: this.organizationId }, { date: this.date }).then(() => {
        this.$emit('save')
        this.close()
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created () {
    this.$bus.$on('modal:generateInvoice', (organizationId) => {
      this.show(organizationId);
    });
  },
  validations: {
  }
}
</script>
