<template>
  <b-card no-header>
    <template slot="header">
      Payment Profiles
    </template>
    <b-list-group>
      <p class="mb-0" v-if="profiles.length === 0">No Cards on File</p>
      <b-list-group-item :href="$can('manage', 'paymentProfiles') ? '#': null" :variant="profile.default ? 'success': null" v-for="profile in profiles" :key="profile.id" class="d-flex justify-content-between align-items-center" @click="$can('manage', 'paymentProfiles') && editCreditCard(profile)">
        <pre style="margin-bottom: 0px;"><i :class="`fa fa-cc-${profile.cardType}`"></i> XXXX-XXXX-XXXX-{{ profile.cardNumber }} exp: {{ formatExpiration(profile.expiration) }}</pre>
        <b-badge v-if="profile.default" variant="secondary" pill>Default</b-badge>
      </b-list-group-item>
    </b-list-group>
    <template slot="footer" v-if="$can('manage', 'paymentProfiles')">
      <b-row>
        <b-col sm="12" class="text-right">
          <b-button size="sm" variant="primary" @click="addCreditCard">Add Card</b-button>
        </b-col>
      </b-row>
    </template>
    <credit-card-modal @save="saveCreditCard"></credit-card-modal>
  </b-card>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'
import moment from 'moment'

import CreditCardModal from '../modals/CreditCard'
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'

export default {
  name: 'PaymentProfiles',
  props: [ 'organizationId' ],
  components: {
    CreditCardModal,
    Date,
    Dollars
  },
  data () {
    return {
      profiles: []
    }
  },
  methods: {
    fetchProfiles () {
      if (!this.organizationId) {
        return
      }
      this.$api.payments.profiles.getWithOrganization({ organizationId: this.organizationId }).then((response) => {
        this.profiles = response.data
      })
    },
    formatExpiration (date) {
      return moment(date).format('MM/YY')
    },
    editCreditCard (profile) {
      this.$bus.$emit('modal:editCreditCard', profile)
    },
    addCreditCard () {
      this.$bus.$emit('modal:editCreditCard')
    },
    saveCreditCard (profile) {
      profile.organizationId = this.organizationId

      if (profile.id) {
        this.$api.payments.profiles.update({ id: profile.id }, profile).then(() => {
          this.$snotify.success('Credit Card Saved')
          this.fetchProfiles()
        }).catch((err) => {
          this.$snotify.error('Credit Card Save Failed')
        })
      } else {
        this.$api.payments.profiles.create(profile).then(() => {
          this.$snotify.success('Credit Card Saved')
          this.fetchProfiles()
        }).catch((err) => {
          this.$snotify.error('Credit Card Save Failed')
        })
      }
    }
  },
  created () {
    this.profiles = []
    this.fetchProfiles()
  },
  watch: {
    organizationId: {
      handler: function () {
        this.fetchProfiles()
      }
    }
  }
}
</script>
